import i18n from "@/i18n.js"

const columnDefs = [
  {
    headerName: `${i18n.t('roles.id')}`,
    traslateName: "roles.id",
    field: "id",
    sortable: true,
    filter: true,
    hide: false,
    showKey: true,
    checkboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerCheckboxSelection: true,
    filterParams: {
      clearButton: true
    },
    width: 100
  },
  {
    headerName: `${i18n.t('roles.roles')}`,
    traslateName: "roles.roles",
    field: "name",
    sortable: true,
    filter: true,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: true
    },
    width: 100
  }
]
export default {
  columnDefs
}