<template lang="pug">
  div
    div(
      v-if="$can('read', 'roles')"
      id="div-with-loading-roles"
      class="vs-con-loading__container"
    )
      ag-grid-table(
        animateRows=true
        suppressRowClickSelection=true
        :columnDefs="chartConfig.columnDefs"
        :rowData="chartConfig.rowData"
        :gridOptions="gridOptions"
        rowSelection="single"
      )
        template(
          v-if="isActionBar()"
          v-slot:actionBar
        )
          ag-action-bar(
            :is_add="$can('create', 'roles')"
            :is_edit="$can('update', 'roles')"
            :is_trash="$can('delete', 'roles')"
            :is_activate="false"
            :is_deactivate="false"
            :is_exportable="false"
            :is_import="false"
            @add="onBtnAdd"
            @trash="onBtnTrash"
            @edit="onBtnEdit"
          )
</template>
<script>
import rolesService from "@/api/roles/roles"
import { validatePermission } from "@/Ability"
import AgGridTable from "@/components/tableAgGrid/Index"
import ActionsBar from "@/components/tableAgGrid/ActionsBar"
import FieldsRoles from "@/fields/roles/fieldsRoles"
import { mapState } from "vuex"
import { translateTableFields } from "@/filter"

export default {
  components: {
    "ag-grid-table": AgGridTable,
    "ag-action-bar": ActionsBar
  },
  data() {
    return {
      gridOptions: {
        localeText: { noRowsToShow: `${this.$t("show_not_data")}` }
      },
      chartConfig: {
        columnDefs: translateTableFields(FieldsRoles.columnDefs),
        rowData: []
      },
      selectedDevices: []
    }
  },
  computed: {
    ...mapState("roles", ["rolesEdit", "rolesDelete"])
  },
  watch: {
    rolesEdit(val) {
      if (val != null) {
        validatePermission(this, "change", "permission", "roles", () =>
          this.setRoleInStore(this.rolesEdit)
        )
      }
    },
    rolesDelete(val) {
      if (val != null) {
        this.onBtnTrashInline(val)
      }
    }
  },
  methods: {
    onBtnTrashInline(data) {
      this.$swal({
        position: "center",
        type: "info",
        title: this.$t("roles.delete_message"),
        confirmButtonColor: "#FDD402",
        cancelButtonColor: "#ea5455",
        showCancelButton: true,
        showConfirmButton: true
      }).then(result => {
        if (result.value) {
          validatePermission(this, "delete", "permission", "roles", () =>
            this.deleteRole(data.id)
          )
        } else {
          this.$store.dispatch("newnessServices/set_delete_newness", null)
        }
      })
    },
    getRoles() {
      this.$vs.loading({
        container: "#div-with-loading-roles",
        type: "material",
        scale: 1
      })
      rolesService
        .getAllRolesData()
        .then(response => {
          this.chartConfig.rowData = response.data
          this.$vs.loading.close("#div-with-loading-roles > .con-vs-loading")
        })
        .catch(() => {
          this.$vs.loading.close("#div-with-loading-roles > .con-vs-loading")
        })
    },
    isActionBar() {
      if (
        this.$can("view", "roles") ||
        this.$can("add", "roles") ||
        this.$can("change", "roles") ||
        this.$can("delete", "roles")
      ) {
        return true
      } else {
        return false
      }
    },
    showDeleteAlert(callback) {
      this.$swal({
        position: "center",
        type: "warning",
        confirmButtonColor: "#FDD402",
        cancelButtonColor: "#ea5455",
        title: this.$t("roles.delete_message"),
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: this.$t("done-text"),
        cancelButtonText: this.$t("cancel-text")
      }).then(callback)
    },
    onBtnEdit() {
      let data = this.gridOptions.api.getSelectedNodes()
      if (data.length === 1) {
        validatePermission(this, "update", "roles", "home", () =>
          this.setRoleInStore(data[0].data)
        )
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle"
        })
      }
    },
    setRoleInStore(currentRole) {
      window.localStorage.setItem("currentRole", JSON.stringify(currentRole))
      this.$store.dispatch("roles/setCurrentRole", currentRole).then(() => {
        this.$router.push({ 
          name: "pageRoleEdit",
          params: { id: currentRole.id }
        })
      })
    },
    onBtnTrash() {
      let data = this.gridOptions.api.getSelectedNodes()
      if (data.length === 1) {
        validatePermission(this, "delete", "roles", "home", () =>
          this.deleteRole(data[0].data.id)
        )
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle"
        })
      }
    },
    deleteRole(id) {
      this.showDeleteAlert(res => {
        if (res.value == true) {
          if (id) {
            rolesService.deleteRole(id).then(() => {
              this.getRoles()
            })
          }
        }
      })
    },
    onBtnAdd() {
      validatePermission(this, "create", "roles", "Home", () => {
        this.$store
          .dispatch("roles/setCurrentRole", { id: null, name: "" })
          .then(() => {
            this.$router.push({ name: "Role" })
          })
      })
    }
  },
  mounted() {
    this.getRoles()
    window.localStorage.removeItem("currentRole")
    this.$store.dispatch("roles/setCurrentRole", {}).then(() => {})
    this.onBtnEdit.bind(this)
  }
}
</script>
<style>
.popup-edit-device .vs-input {
  float: left;
  width: 50%;
  margin: 10px;
  margin-top: 5px;
}
.popup-edit-device .con-select {
  margin-left: 10px;
  width: 50%;
  margin-bottom: 10px;
}
.sm-input {
  width: 100% !important;
}
div.inline-blocks {
  display: inline-block !important;
}
div.inline-blocks div {
  padding: 4px;
}
.activity-select {
  margin: 10px;
  top: -1px !important;
}
.activity-select * {
  border-color: #FDD402 !important;
  color: #FDD402 !important;
}
</style>
