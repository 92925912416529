<template lang="pug">
  vue-roles
</template>

<script>

import Roles from "@/components/roles/Index"
import {validatePermission} from '@/Ability'

const COMPONENT_NAME= "Roles"

export default {
  name: COMPONENT_NAME,
  components: {
    "vue-roles": Roles
  },
  created(){
    validatePermission(this,'read','roles','Home')
  }
}
</script>
